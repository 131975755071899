<template lang="pug">
include ../../../configs/template
form( @submit.prevent="checkInfo" ).row.p-2
  div.col-12
    +select-validation('deliveryType', 'listDeliveryType', 'deliveryType', 'labelName', '["required"]')(return-object)
  div.col-12
    +select-validation('body.morrichservice_office', 'listWerhouse', 'department', 'labelName', '["required"]')(
      v-if="body.type_post === 'morrichservice'")
  div(v-if="deliveryType && deliveryType.id !== 3").col-12
    div
      +select-validation('body.city_id', 'mappingDeliveryCities', 'city',  'labelName', '["required"]')(@input="getDeliveryInfo(body.city_id)")
    div
      +select-validation('body.warehouse_id', 'deliveryWarehouses', 'department',  'labelName', '["required"]')(
        v-if="deliveryType && deliveryType.id === 1"
        :loading="selectLoader")
    div(v-if="deliveryType && deliveryType.id === 2")
      div
        +select-validation('body.street_id', 'deliveryStreets', 'street',  'labelName', '["required"]')(:loading="selectLoader")
      div
        +field-validation('body.house', 'house', '["required"]')
      div
        +field-validation('body.apartment', 'flat', '["required"]')
  div.col-12
    +field-validation('body.phone_number', 'phoneNumber', '["required"]')(v-mask="'(###) ###-##-##'" prefix="+38")
  div.col-12
    FileDropZone(ref="mediaContent" v-if="checkAccess('recordBookStatement', 'add_file')")
  div.col-12
    v-btn.mt-3(:loading="buttonLoader" color="success" type="submit") {{ $t('save') }}

</template>

<script>
import { mapActions, mapState } from 'vuex'
import { statementSRB } from '@/mixins/validationRules'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '@/configs/constants'

const initBody = () => ({
  type_post: null,
  city_id: null,
  warehouse_id: null,
  street_id: null,
  house: null,
  apartment: null,
  phone_number: null,
  is_courier: false,
  morrichservice_office: null
})
export default {
  components: { FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue') },
  props: { statement: { type: Object, default: () => ({}) } },
  data () {
    return {
      deliveryType: null,
      selectLoader: false,
      body: initBody(),
      deliveryStreets: [],
      deliveryWarehouses: [],
      buttonLoader: false,
      files: [],
      checkAccess
    }
  },
  computed: {
    ...mapState({
      labelName: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      id: (state) => state.sailor.sailorId,
      mappingDeliveryCities: (state) => state.directory.deliveryCities,
      user: (state) => state.main.user,
      listDeliveryType: (state) => state.delivery.typeDelivery.filter(el => !el.is_disable),
      listWerhouse: state => state.delivery.listWearHouseMorrichservice
    })
    // listDelivery () {
    // return this.listDeliveryType.filter(el => this.user.type_user === 'morrichservice' ? el.id === 3 : el.id !== 3)
    // }
  },
  validations () { return statementSRB(this) },
  watch: {
    deliveryType (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cleanAddress()
        this.body.type_post = newVal?.delivery_service
        this.body.is_courier = newVal?.courier || newVal?.name_eng.includes('courier') || false
      }
    }
  },
  methods: {
    ...mapActions(['getWearHouseNovaPoshta', 'getStreetNovaPoshta', 'createServiceRecordStatement']),
    checkInfo () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.addApplicationRB()
    },

    cleanAddress () {
      let cleanFields = ['type_post', 'city_id', 'warehouse_id', 'street_id', 'house', 'apartment',
        'morrichservice_office']
      cleanFields.forEach(key => (this.body[key] = null))
    },

    async getDeliveryInfo (id) {
      this.selectLoader = true
      this.body.warehouse_id = null
      this.body.street_id = null
      if (this.deliveryType.id === 1) {
        const response = await this.getWearHouseNovaPoshta({ id })
        this.selectLoader = false
        if (SUCCESS_CODE.includes(response.code)) {
          this.deliveryWarehouses = response.data
        }
      } else if (this.deliveryType.id === 2) {
        const response = await this.getStreetNovaPoshta({ id })
        this.selectLoader = false
        if (SUCCESS_CODE.includes(response.code)) {
          this.deliveryStreets = response.data
        }
      }
    },
    async addApplicationRB () {
      this.buttonLoader = true
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_service_record'
        }

      }
      Object.entries(data.body).forEach(([key, value]) => {
        if (value === null) delete data.body[key]
        if (key === 'phone_number') {
          const phone = data.body.phone_number.replace(/[- ()]/g, (match) => {
            if (match === '(') return '+38'
            return ''
          })
          data.body.phone_number = phone
        }
      })
      Object.keys(this.statement).length && (data.body.wish_statement = this.statement.id)

      const response = await this.createServiceRecordStatement(data)
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$notification.success('submittedStatementRB')
        this.$parent.isViewAddSlot = false
        this.body = initBody()
        this.deliveryType = null
        this.$v.$reset()
      }
      this.buttonLoader = false
    }
  }
}
</script>
